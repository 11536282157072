<template>
<div class="students">
  <h1 class="title grey--text">学员列表</h1>
  <v-container class="my-5">
    <v-card class="ma-2 pa-2"
      v-for="(student, indexStudent) in students" v-bind:key="`student-${indexStudent}`"
    >
      <v-layout align-center justify-center row wrap class="pa-4 student-details">
        <v-flex xs12 md3>
          <!-- <div class="caption grey--text">学员称呼</div> -->
          <div class="title font-weight-bold">{{ student.student_name }}{{ student.studentRealName? `/${student.studentRealName}`: ''}}{{ student.studentEnglishName? `/${student.studentEnglishName}`: ''}}
          </div>
        </v-flex>
        <v-flex xs4 md1>
          <div class="caption grey--text">学员年龄</div>
          <div class="subtitle-2">{{ student.ageString }}</div>
        </v-flex>
        <v-flex xs2 md1>
          <div class="caption grey--text">性别</div>
          <div class="subtitle-2">{{ student.sex }}</div>
        </v-flex>
        <v-flex xs6 md2>
          <div class="caption grey--text">所在班级</div>
          <div class="subtitle-2">{{ student.class }}</div>
        </v-flex>
        <v-flex xs12 md1>
          <div class="caption grey--text">所剩课时</div>
          <v-chip outlined small :class="`${student.lesson_quantity - student.unfinalizedLessonUsageQuantity > 10 ? 'green--text' : 'red--text'}`">
            <div class="subtitle-2" >{{ `${student.lesson_quantity}${student.unfinalizedLessonUsageQuantity > 0 ? ` - ${student.unfinalizedLessonUsageQuantity}` : ''} ` }}</div>
          </v-chip>
        </v-flex>
        <v-flex xs12 md3>
          <div class="caption grey--text">培训项目</div>
          <div class="subtitle-2">{{ student.program }}</div>
        </v-flex>
        <v-flex xs12 md1>
          <v-chip
            :style="{ cursor: 'pointer'}"
            @click="$router.push({
              name: 'student-details',
              params:{
                studentId: student.student_id,
                programId: student.program_id
              }
            })"
            color="grey"
            text-color="white"
            small
          >
            详情
            <v-icon right small>info</v-icon>
          </v-chip>
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
  <!-- display overlay on the whole page while loading -->
  <v-overlay :value="$store.state.queryingState">
    <v-progress-circular
      indeterminate
      color=""
    ></v-progress-circular>
  </v-overlay>
</div>
</template>

<script>
import ProgramService from '@/services/ProgramService'
import OrganizationService from '../services/OrganizationService'
import { differenceInCalendarMonths } from 'date-fns'

export default {
  components: {
  },
  data () {
    return {
      organizationIds: [],
      tutorIds: [],
      programIds: [],
      managerIds: [],
      inviterIds: [],
      classIds: [],
      students: []
    }
  },
  computed: {
  },
  watch: {
    async $route (to, from) {
      if (this.$route.query.organizationId || this.$route.query.tutorId || this.$route.query.programId) { // 如果存在任何路径参数，则优先考虑
        console.log('检测到路径参数变化')
        this.$store.dispatch('setQueryingState', true)
        console.log(this.$route.query)
        this.resetData()
        if (this.$route.query.organizationId) {
          this.organizationIds = [this.$route.query.organizationId]
        }
        if (this.$route.query.tutorId) {
          this.tutorIds = [this.$route.query.tutorId]
        }
        if (this.$route.query.programId) {
          this.programIds = [this.$route.query.programId]
        }
        if (this.$route.query.managerId) {
          this.managerIds = [this.$route.query.managerId]
        }
        if (this.$route.query.organizationId) {
          this.organizationIds = [this.$route.query.organizationId]
        }
        if (this.$route.query.classId) {
          this.classIds = [this.$route.query.classId]
        }
        if (this.$route.query.inviterId) {
          this.inviterIds = [this.$route.query.inviterId]
        }
        this.students = await this.getStudents()
        this.$store.dispatch('setQueryingState', false)
      }
    }
  },
  async mounted () {
    console.log('\n#Page: Students')
    this.$store.dispatch('setQueryingState', true)

    // detect query
    if (this.$route.query.organizationId || this.$route.query.tutorId || this.$route.query.programId) { // 如果存在任何路径参数，则优先考虑
      console.log('检测到路径参数')
      console.log(this.$route.query)
      this.resetData()
      if (this.$route.query.organizationId) {
        this.organizationIds = [this.$route.query.organizationId]
      }
      if (this.$route.query.tutorId) {
        this.tutorIds = [this.$route.query.tutorId]
      }
      if (this.$route.query.programId) {
        this.programIds = [this.$route.query.programId]
      }
      if (this.$route.query.managerId) {
        this.managerIds = [this.$route.query.managerId]
      }
      if (this.$route.query.organizationId) {
        this.organizationIds = [this.$route.query.organizationId]
      }
      if (this.$route.query.classId) {
        this.classIds = [this.$route.query.classId]
      }
      if (this.$route.query.inviterId) {
        this.inviterIds = [this.$route.query.inviterId]
      }
    } else { // 如果没有任何中路径参数, 考虑查找当前用户角色
      console.log('未检测到路径参数，使用户参数！')
      if (this.$store.state.account.manager) {
        const managerIds = [this.$store.state.account.manager.id]
        const res = await OrganizationService.getOrganizationsInfo({ managerIds })
        let organizationsInfo = res.data
        this.organizationIds = organizationsInfo.map(org => org.organizationId)
      } else if (this.$store.state.account.organizations) {
        this.organizationIds = this.$store.state.account.organizations.map(organization => organization.id)
      } else if (this.$store.state.account.tutor) {
        this.tutorIds = [this.$store.state.account.tutor.id]
      }
    }
    console.log('this.tutorIds:')
    console.log(this.tutorIds)
    this.students = await this.getStudents()
    this.$store.dispatch('setQueryingState', false)
  },
  methods: {
    resetData () {
      this.organizationIds = []
      this.tutorIds = []
      this.programIds = []
      this.managerIds = []
      this.inviterIds = []
      this.classIds = []
      this.students = []
    },
    async getStudents () {
      console.log('\n#getStudentsB starts ... ')
      const res = await ProgramService.getStudents({
        programIds: this.programIds,
        managerIds: this.managerIds,
        organizationIds: this.organizationIds,
        studentIds: this.studentIds,
        classIds: this.classIds,
        tutorIds: this.tutorIds,
        inviterIds: this.inviterIds
      })
      // calculatie age
      let students = res.data
      students.forEach(student => {
        let ageInMonth = differenceInCalendarMonths(
          new Date(),
          new Date(parseInt(student.birth_year), parseInt(student.birth_month))
        )
        student.ageString = `${Math.floor(ageInMonth / 12)}Y ${ageInMonth % 12}M`
      })
      console.log('retrieve students successful! \nstudents: ', res.data)
      return res.data
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
